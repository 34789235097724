import React from 'react';
import WaistManual from "../../components/WaistManual";
import useWaistESImages from "../../hooks/useWaistESImages";

const locale = "es";
export default () => {
	const images = useWaistESImages();
	return (
		<>
			<WaistManual locale={locale} images={images}/>
		</>
	);
}
